import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: { customProperties: true },

    themes: {
      light: {
        deleteConfirmation: colors.red,
        background: "#f8f8f8",
        primary: "#CB333B",
      },
    },
  },
});
