import VueRouter, { RouteConfig } from "vue-router";
import { useUserStore } from "@/stores/userStore";
import { NavLink } from "@/constants/navLink";
import { PageTitle } from "@/constants/enums/pageTitles";
import { nextTick } from "@vue/composition-api";
import { AgentRole, ResourceType2 } from "@/api/LumediApi";

const DEFAULT_TITLE = "SDM - MWMCC";

declare module "vue-router" {
  interface RouteMeta {
    title: string;
    allowAnonymous?: boolean;
    adminOnly?: boolean;
    agentOnly?: boolean;
  }
}

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "home",
    redirect: "hub",
  },

  // * Hub
  {
    path: "/hub",
    name: "hub",
    component: () => import("@/views/HubView.vue"),
    meta: { title: PageTitle.Hub },
  },

  // * Authentication
  {
    path: "/auth/login",
    name: "login",
    component: () => import("@/views/authentication/LoginView.vue"),
    meta: { allowAnonymous: true, title: PageTitle.Login },
  },
  {
    path: "/auth/register",
    name: "register",
    component: () => import("@/views/authentication/RegisterView.vue"),
    meta: { allowAnonymous: true, title: PageTitle.Register },
  },
  {
    path: "/auth/logout",
    name: "logout",
    component: {
      beforeRouteEnter(to, from, next) {
        const userStore = useUserStore();
        userStore.logout();
        if (to.params.clear) return next({ name: "login" });
        next({ name: "login", query: { return: from.fullPath } });
      },
    },
  },
  {
    path: "/auth/forgot-password",
    name: "forgot-password",
    component: () => import("@/views/authentication/ForgotPasswordView.vue"),
    meta: { allowAnonymous: true, title: PageTitle.ForgotPassword },
  },
  {
    path: "/auth/forgot-password/reset",
    name: "forgot-password-reset",
    component: () => import("@/views/authentication/RegisterView.vue"),
    props: { reset: true },
    meta: { allowAnonymous: true, title: PageTitle.ResetPassword },
  },
  {
    path: "/auth/duplicateSession",
    name: "duplicate-session",
    component: () => import("@/views/authentication/DuplicateSessionView.vue"),
    meta: { allowAnonymous: true, title: PageTitle.DuplicateSession },
  },

  // ! Admin Portal
  // Require authorization
  {
    path: "/admin",
    component: () => import("@/components/layout/AppLayout.vue"),
    redirect: "admin-dashboard",
    props: {
      /** The navigation links. */
      navLinks: [
        {
          toName: "admin-dashboard",
          title: PageTitle.Dashboard,
        },
        {
          toName: "agent-management",
          title: PageTitle.Agents,
        },
        {
          toName: "equipment-management",
          title: PageTitle.Equipment,
        },
        {
          toName: "training-management",
          title: PageTitle.TrainingDocumentation,
        },

        {
          toName: "invoice-management",
          title: PageTitle.Invoices,
        },
        {
          toName: "accounts-management",
          title: PageTitle.Accounts,
        },
        {
          toName: "settings-management",
          title: PageTitle.Settings,
        },
      ] as NavLink[],
    },
    children: [
      // * Dashboard
      {
        path: "dashboard",
        name: "admin-dashboard",
        component: () => import("@/views/admin-portal/DashboardView.vue"),
        meta: { adminOnly: true, title: PageTitle.Dashboard },
      },
      {
        path: "dashboard/notes",
        name: "admin-dashboard-notes",
        component: () => import("@/views/admin-portal/DashboardView.vue"),
        props: { notes: true },
        meta: { adminOnly: true, title: PageTitle.Dashboard },
      },

      // * Agent Management
      {
        path: "agents",
        name: "agent-management",
        component: () => import("@/views/agent-management/AgentManagementView.vue"),
        props: (route) => ({ search: route.query.search, filter: +route.query.filter }),
        meta: { adminOnly: true, title: PageTitle.Agents },
      },
      {
        path: "agents/create",
        name: "agent-management-create",
        component: () => import("@/views/agent-management/AgentManagementCreateView.vue"),
        meta: { adminOnly: true, title: PageTitle.Agents },
      },
      {
        path: "agents/details/:agentId",
        name: "agent-management-details",
        component: () => import("@/views/agent-management/AgentManagementDetailsView.vue"),
        props: true,
        meta: { adminOnly: true, title: PageTitle.Agents },
      },

      // * Equipment Management
      {
        path: "equipment",
        name: "equipment-management",
        component: () => import("@/views/admin-portal/equipment-management/EquipmentManagementView.vue"),
        props: (route) => ({ search: route.query.search, tab: +route.query.tab }),
        meta: { adminOnly: true, title: PageTitle.Equipment },
      },
      {
        path: "equipment/create",
        name: "equipment-create",
        component: () =>
          import("@/views/admin-portal/equipment-management/EquipmentDeviceCreateView.vue"),
        props: true,
        meta: { adminOnly: true, title: PageTitle.Equipment },
      },

      // * Agent Management
      {
        path: "training",
        name: "training-management",
        component: () =>
          import("@/views/admin-portal/training-management/TrainingManagementDashboard.vue"),
        meta: { adminOnly: true, title: PageTitle.TrainingDocumentation },
      },
      {
        path: "training/course/:role",
        name: "course-management",
        component: () => import("@/views/admin-portal/training-management/CourseManagementView.vue"),
        props: true,
        meta: { adminOnly: true, title: PageTitle.TrainingDocumentation },
      },
      {
        path: "training/documents",
        name: "document-management",
        component: () => import("@/views/admin-portal/training-management/ResourceManagementView.vue"),
        props: { type: ResourceType2.Documentation },
        meta: { adminOnly: true, title: PageTitle.TrainingDocumentation },
      },
      {
        path: "training/resources",
        name: "resource-management",
        component: () => import("@/views/admin-portal/training-management/ResourceManagementView.vue"),
        props: { type: ResourceType2.Resource },
        meta: { adminOnly: true, title: PageTitle.TrainingDocumentation },
      },
      {
        path: "training/support-requests",
        name: "training-support-requests",
        component: () =>
          import("@/views/admin-portal/training-management/TrainingSupportRequestsView.vue"),
        meta: { adminOnly: true, title: PageTitle.TrainingDocumentation },
      },
      // * Invoice Management
      {
        path: "invoices",
        name: "invoice-management",
        component: () => import("@/views/invoices/admin/InvoiceManagementView.vue"),
        props: (route) => ({ role: +route.query.role, status: +route.query.status }),
        meta: { adminOnly: true, title: PageTitle.Invoices, onlyShowForAdminsAndDietitians: true },
      },
      {
        path: "invoices/create/:agentId?",
        name: "invoice-management-create",
        component: () => import("@/views/invoices/InvoiceCreateView.vue"),
        props: (route) => ({
          ...route.query,
          ...route.params,
          roles: route.query.roles ? [...route.query.roles] : null,
        }),
        meta: { adminOnly: true, title: PageTitle.Invoices },
      },
      {
        path: "invoices/edit/:invoiceId",
        name: "invoice-management-edit",
        component: () => import("@/views/invoices/InvoiceCreateView.vue"),
        props: true,
        meta: { adminOnly: true, title: PageTitle.Invoices },
      },

      // * Accounts Management
      {
        path: "accounts",
        name: "accounts-management",
        component: () => import("@/views/admin-portal/accounts-management/AccountsManagementView.vue"),
        meta: { adminOnly: true, title: PageTitle.Accounts },
      },
      {
        path: "accounts/create",
        name: "accounts-admin-create",
        component: () =>
          import("@/views/admin-portal/accounts-management/AccountsManagementCreateView.vue"),
        props: true,
        meta: { adminOnly: true, title: PageTitle.Accounts },
      },

      // * Settings Management
      {
        path: "settings",
        name: "settings-management",
        component: () => import("@/views/admin-portal/settings-management/SettingsManagementView.vue"),
        meta: { adminOnly: true, title: PageTitle.Settings },
      },
    ],
  },

  // ! Agent Portal
  {
    path: "/agent",
    redirect: "agent-profile",
    component: () => import("@/components/layout/AppLayout.vue"),
    props: {
      /** The navigation links. */
      navLinks: [
        {
          toName: "agent-profile",
          title: PageTitle.Profile,
        },
        {
          toName: "agent-training",
          title: PageTitle.TrainingDocumentation,
        },
        {
          toName: "agent-invoice-dashboard",
          title: PageTitle.Invoices,
          onlyShowForAdminsAndDietitians: true,
        },
      ] as NavLink[],
    },
    children: [
      // * Profile
      {
        path: "profile",
        name: "agent-profile",
        component: () => import("@/views/agent-management/AgentManagementDetailsView.vue"),
        meta: { agentOnly: true, title: PageTitle.Profile },
      },

      // * Training & Documentation
      {
        path: "training",
        name: "agent-training",
        component: () => import("@/views/agent-portal/training/TrainingDashboardView.vue"),
        meta: { agentOnly: true, title: PageTitle.TrainingDocumentation },
      },
      {
        path: "training/module/:moduleId",
        name: "agent-training-module",
        component: () => import("@/views/agent-portal/training/TrainingModuleView.vue"),
        meta: { agentOnly: true, title: PageTitle.TrainingDocumentation },
        props: true,
      },
      {
        path: "training/module/:moduleId/task/:taskId",
        name: "agent-training-task",
        component: () => import("@/views/agent-portal/training/TrainingTaskView.vue"),
        meta: { agentOnly: true, title: PageTitle.TrainingDocumentation },
        props: true,
      },
      {
        path: "training/resources/:resourceId",
        name: "agent-resource",
        component: () => import("@/views/agent-portal/resources/ResourcesView.vue"),
        meta: { agentOnly: true, title: PageTitle.TrainingDocumentation },
        props: true,
      },
      // * Invoice Management
      {
        path: "invoices",
        name: "agent-invoice-dashboard",
        component: () => import("@/views/invoices/InvoiceAgentDashboard.vue"),
        meta: { agentOnly: true, title: PageTitle.Invoices },
      },
      {
        path: "invoices/create",
        name: "agent-invoice-create",
        component: () => import("@/views/invoices/InvoiceCreateView.vue"),
        meta: { agentOnly: true, title: PageTitle.Invoices },
      },
      {
        path: "invoices/edit/:invoiceId",
        name: "agent-invoice-edit",
        component: () => import("@/views/invoices/InvoiceCreateView.vue"),
        meta: { agentOnly: true, title: PageTitle.Invoices },
        props: true,
      },
    ],
  },

  // ! Fallback routes
  {
    path: "/admin/agent*",
    redirect: { name: "agent-management" },
  },
  {
    path: "/admin/equipment*",
    redirect: { name: "equipment-management" },
  },
  {
    path: "/admin/training*",
    redirect: { name: "training-management" },
  },
  {
    path: "/admin/accounts*",
    redirect: { name: "accounts-management" },
  },
  {
    path: "/admin/settings*",
    redirect: { name: "settings-management" },
  },
  {
    path: "/admin*",
    redirect: { name: "admin-dashboard" },
  },
  {
    path: "/agent/training*",
    redirect: { name: "agent-training" },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// Before each page route.
router.beforeEach(async (to, _from, next) => {
  const userStore = useUserStore();

  // Scroll page to top on every route change
  window.scrollTo(0, 0);

  // Redirect unauthenticated user to login.
  if (!userStore.checkIsAuthenticated() && !to.meta?.allowAnonymous) {
    next({ name: "login", query: { return: to.fullPath } });
    return;
  }
  // Redirect authenticated user accessing login to home screen.
  if (to.name === "login" && userStore.checkIsAuthenticated()) {
    next({ name: "home" });
    return;
  }

  // Redirect non-admin user accessing admin route.
  if (to.meta?.adminOnly && !userStore.isAdministrator) {
    next({ name: "home" });
    return;
  }

  if (userStore.isAdministrator && to.meta?.agentOnly) {
    next({ name: "home" });
    return;
  }

  next();
});

router.afterEach((to) => {
  // Set the page title
  nextTick(() => {
    document.title = to.meta?.title ? `${to.meta?.title} | ${DEFAULT_TITLE}` : DEFAULT_TITLE;
  });
});

export default router;
