// Vue
import Vue from "vue";
// Composition API
import VueCompositionAPI from "@vue/composition-api";
// Pinia
import { createPinia, PiniaVuePlugin } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
// Vue Router
import router from "./router";
import VueRouter from "vue-router";
// Vuetify
import vuetify from "@/plugins/vuetify";
// Day.js
import { DayJsPlugin } from "@/plugins/dayjs";
// App
import App from "./App.vue";

// Create Pinia instance
Vue.use(PiniaVuePlugin);
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

// Usings
Vue.use(VueCompositionAPI);
Vue.use(VueRouter);
Vue.use(DayJsPlugin);

Vue.config.productionTip = false;

new Vue({
  pinia,
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
