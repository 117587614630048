import axios, { AxiosResponse } from "axios";
// Stores
import { useMessageStore } from "@/stores/messageStore";
import router from "@/router";
export default abstract class AxiosClient {
  public constructor() {
    axios.defaults.withCredentials = true;
    axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
  }

  protected transformOptions(options: any) {
    return Promise.resolve(options);
  }

  protected transformResult(
    _url: string,
    response: AxiosResponse,
    processor: (response: AxiosResponse) => any,
  ) {
    switch (response.status) {
      case 400:
        this.showErrorMessage("Bad request.");
        break;
      case 401:
        {
          // Unauthorized.
          router.push({ name: "logout" });
          this.showErrorMessage("Authorization required.");
        }
        break;
      case 403:
        this.showErrorMessage("Access to that resource is forbidden.");
        break;
      case 404:
        this.showErrorMessage("The requested resource was not found.");
        break;
      case 500:
        this.showErrorMessage(
          "There was an error on the server and the request could not be completed.",
        );
        break;
      case 0:
        this.showErrorMessage("There was a network error and the request could not be completed.");
        break;
    }

    return processor(response);
  }

  /** Adds an error message to the store. */
  private showErrorMessage(message: string): void {
    const messageStore = useMessageStore();
    messageStore.addErrorMessage(message);
  }
}
