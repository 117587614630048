export enum PageTitle {
  Hub = "Hub",

  Login = "Login",

  Register = "Register",

  ForgotPassword = "Forgot Password",

  ResetPassword = "Reset Password",

  DuplicateSession = "Loading...",

  Dashboard = "Dashboard",

  Equipment = "Equipment",

  Agents = "Agents",

  TrainingDocumentation = "Training & Documentation",

  Invoices = "Invoices",

  Profile = "Profile",

  Accounts = "Accounts",

  Settings = "Settings",
}
