import { MWMSettingsClient } from "../api/LumediApi";
import { defineStore } from "pinia";
import { useMessageStore } from "./messageStore";

export const useSettingsStore = defineStore("settings", {
  persist: true,

  // * State
  state: () => ({
    maxFileSize: 16 * 1024 * 1024,
  }),

  // * Getters
  getters: {},

  // * Actions
  actions: {
    /** Gets settings from the server */
    async loadSettings(): Promise<void> {
      try {
        const settingsClient = new MWMSettingsClient();
        const publicSettings = await settingsClient.getPublicSettings();

        this.maxFileSize = publicSettings.fileUploadSettings.maxSize;
      } catch (e) {
        const messageStore = useMessageStore();
        messageStore.addErrorMessage("Failed to load settings from API.");
      }
    },
  },
});
