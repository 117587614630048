// Pinia
import { defineStore } from "pinia";
// API
import { computed, ref } from "@vue/composition-api";

/** Managing everything regarding messages.
 *  Any message that is added to this store will be displayed as a snackbar in the app. */
export const useMessageStore = defineStore("message", () => {
  // * Message
  /** The current message. */
  const message = ref<string>();
  /** The current message color. */
  const messageColor = ref<string>();
  /** Whether or not there is currently an  message. */
  const hasMessage = computed(() => message.value != null);
  // * Message

  // * Add message
  const timeoutId = ref<number>();
  /** Sets the message and clears it after a timeout. */
  const addMessage = (inputMessage: string, color = "primary", timeout = 5000) => {
    clearTimeout(timeoutId.value);

    message.value = inputMessage;
    messageColor.value = color;

    timeoutId.value = setTimeout(() => (message.value = undefined), timeout);
  };

  /** Adds a message with the error color. */
  const addErrorMessage = (inputMessage: string) => {
    addMessage(inputMessage, "error");
  };

  /** Adds a message with the success color. */
  const addSuccessMessage = (inputMessage: string) => {
    addMessage(inputMessage, "success");
  };
  // * Add message

  return {
    message,
    messageColor,
    hasMessage,
    addErrorMessage,
    addSuccessMessage,
  };
});
