import {
  AccountAuthenticateModel,
  AccountAuthenticateModelResponse,
  AccountClient,
  AgentRole,
  MWMAccountClient,
  MWMLoginClient,
  SetCurrentClientStudyRequest,
} from "./../api/LumediApi";
import { defineStore } from "pinia";
import { useMessageStore } from "./messageStore";
import { useSettingsStore } from "./settingsStore";

//define enum
export enum LoginResponse {
  Locked,
  Success,
  TwoFactor,
  Failed,
}
export const useUserStore = defineStore("user", {
  persist: true,

  // * State
  state: () => ({
    _storeIsAuthenticated: false, // don't use this value since it won't be changed when other apps log out. Use `checkIsAuthenticated` instead
    displayName: undefined as string | undefined,
    isAdministrator: true,
    agentRole: undefined as AgentRole | undefined,
    resendToken: undefined as string | undefined,
  }),

  // * Getters
  getters: {},

  // * Actions
  actions: {
    async resendTwoFactorCode(): Promise<boolean> {
      const loginClient = new MWMLoginClient();
      try {
        const response = await loginClient.doResendTwoFactorCode(this.resendToken ?? "");
        return response;
      } catch {
        return false;
      }
    },
    /** Authenticates a user. */
    async login(username: string, password: string, twoFactorCode: string): Promise<LoginResponse> {
      const loginClient = new MWMLoginClient();
      try {
        const model = new AccountAuthenticateModel({ username, password, twoFactorCode });

        const response = await loginClient.doAuthenticate(model);

        if (response.twoFactor) {
          this.resendToken = response.resendToken;
          return LoginResponse.TwoFactor;
        }

        this.resendToken = undefined;

        if (response.locked) return LoginResponse.Locked;

        if (!response.success) return LoginResponse.Failed;

        this.displayName = response.displayName;

        // Set various sessionStorage items used by the records app

        if(response.success && response.passwordExpired){
          const messageStore = useMessageStore();
          messageStore.addErrorMessage("Authentication failed.");
          return LoginResponse.Failed;
        }
        if (response.success) {
          const lumediAccountClient = new AccountClient();

          const getClientAndStudy = async () => {
            const availableClients = await lumediAccountClient.getClientsAndStudiesForLoggedInUser();
            const client = availableClients[0];
            if (client == undefined) throw new Error("User has no clients");

            const study = (client.studies ?? [])[0];
            if (study == undefined) throw new Error("User has no studies");

            const setClientStudyResult =
              await lumediAccountClient.setCurrentClientAndStudyForLoggedInUser(
                new SetCurrentClientStudyRequest({ clientID: client.clientID, studyID: study.studyID }),
              );

            sessionStorage.setItem("client_id", setClientStudyResult.clientID);
            sessionStorage.setItem("client_name", setClientStudyResult.clientName ?? "");
            sessionStorage.setItem("study_id", setClientStudyResult.studyID);
            sessionStorage.setItem("study_name", setClientStudyResult.studyName ?? "");
          };

          sessionStorage.setItem("access_token", "");

          // Account
          const accountClient = new MWMAccountClient();
          const getCurrentUserRole = accountClient.getCurrentUserRole().then((x) => {
            this.isAdministrator = x.isAdmin;
            this.agentRole = x.agentRole;
          });

          // Settings
          const settingsStore = useSettingsStore();
          const loadSettings = settingsStore.loadSettings();

          await Promise.all([getCurrentUserRole, loadSettings, getClientAndStudy()]);

          this._storeIsAuthenticated = true;

          return LoginResponse.Success;
        }
        return LoginResponse.Failed;
      } catch (e) {
        const messageStore = useMessageStore();
        messageStore.addErrorMessage("Authentication failed.");
        return LoginResponse.Failed;
      }
    },

    /** Logs a user out by resetting the store and clearing various session storage values. */
    async logout(): Promise<boolean> {
      this.$reset();
      document.cookie = "lumedi.auth=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;";
      sessionStorage.removeItem("accountID");
      sessionStorage.removeItem("credentials");
      sessionStorage.removeItem("access_token");
      sessionStorage.removeItem("client_id");
      sessionStorage.removeItem("client_name");
      sessionStorage.removeItem("study_id");
      sessionStorage.removeItem("study_name");
      return true;
    },

    /** Checks if a user is actually authenticated, including for other apps */
    checkIsAuthenticated(): boolean {
      // the return of this function can't be reactive since Vue won't react to sessionStorage or cookie changes
      return (
        this._storeIsAuthenticated &&
        sessionStorage.getItem("access_token") != null &&
        sessionStorage.getItem("client_id") != null &&
        sessionStorage.getItem("client_name") != null &&
        sessionStorage.getItem("study_id") != null &&
        sessionStorage.getItem("study_name") != null &&
        (Object.fromEntries(document.cookie.split("; ").map((x) => x.split("=")))["lumedi.auth"] ??
          "") != ""
      );
    },
  },
});
